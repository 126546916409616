.google-map-container {
  position: relative;
  height: 350px;
  width: 100%;
  max-width: 100%;

  .geocoder-input {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 40%;
    z-index: 1; }

  .map {
    width: 100%;
    height: 100%; } }

