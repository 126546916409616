body {
  background: #F5F8F7; }

.sidebar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background: $sidebar-bg;
  width: 100%;

  @media (min-width: $screen-sm-min) {
    @include fadeInLeft();
    top: 0;
    right: auto;
    width: $sidebar-width;
    box-shadow: -3px 0 3px darken($sidebar-bg, 8%) inset;

    .sidebar-brand {
      height: 60px;
      padding: 20px 15px 10px 15px;
      text-align: center; }

    .brand {
      height: 100%; } } }

.logout {
  position: absolute;
  bottom: 0;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  width : 100%;
  background: darken($sidebar-bg, 5%);
  color: lighten($sidebar-bg, 25%);
  &:hover {
    text-decoration: none; } }

.main-nav {
  margin: 0;

  @media (min-width: $screen-sm-min) {
    margin-top: 20px; }

  li {
    display: inline-block;
    text-align: center;
    width: 24%;
    @media (min-width: $screen-sm-min) {
      text-align: left;
      width: 100%;
      display: block; } }

  li a {
    display: inline-block;
    position: relative;
    color: lighten($sidebar-bg, 50%);
    font-size: 1em;
    text-decoration: none;

    width: 100%;
    padding: 15px;

    &:hover {
      background: lighten($sidebar-bg, 2%);
      text-decoration: none;
      color: lighten($sidebar-bg, 75%);
 }      //color: $brand-primary

    &.active {
      //color: #FFF
      color: $brand-primary;
      background: darken($sidebar-bg, 4%);
      font-weight: bold;

      //&:before
      //  content: ''
      //  position: absolute
      //  bottom: 0
      //  left: 0
      //  right: 0
      //  height: 100%
 }      //  border-bottom: 4px $brand-primary solid

    @media (min-width: $screen-sm-min) {
      display: block;
      transition: color 0.15s, background 0.15s;
      padding: 12px;
      padding-left: 20px;

      span,
      i {
        vertical-align: middle; }

      //span
      //  font-weight: bold

      i {
        width: 1.2em;
        font-size: 1.5em; }

      &.active {
        &:before {
          border: 0;
 } } } } }          //border-left: 4px $brand-primary solid


.main {
  padding: 10px 20px;
  padding-top: 60px + 10px;
  padding-bottom: 60px + 10px;

  @media (min-width: $screen-sm-min) {
    padding-bottom: 10px;
    padding-left: $sidebar-width + 20px; } }

.action-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 15px 20px;
  background: #FFF;
  border-bottom: 1px #eee solid;
  z-index: 3; // overlapping .input-group

  @media (min-width: $screen-sm-min) {
    left: $sidebar-width; }

  .btn {
    margin-top: -4px; }

  .clearfix {}

  .breadcrumb {
    float: left;
    a,
    .active {
      font-size: 1.2em; } } }
