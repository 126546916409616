//// Backgrounds
$ember-power-select-background-color: darken($body-bg, 2%); // $input-bg // !default
//$ember-power-select-disabled-background-color: #eeeeee !default
//$ember-power-select-multiple-selection-background-color: #e4e4e4 !default
$ember-power-select-highlighted-background: $brand-primary; //#5897fb !default
$ember-power-select-selected-background: #dddddd; // !default
//
//// Texts
//$ember-power-select-text-color: inherit !default
//$ember-power-select-placeholder-color: #999999 !default
$ember-power-select-highlighted-color: white; //#ffffff !default
//$ember-power-select-disabled-option-color: #999999 !default
//$ember-power-select-multiple-selection-color: #333333 !default
//
//// Borders
$ember-power-select-border-color: #e3e3e3; // #aaaaaa !default
$ember-power-select-focus-border-color: #a3cbb8; // !default
//$ember-power-select-default-border: 1px solid $ember-power-select-border-color !default
//$ember-power-select-default-focus-border: 1px solid $ember-power-select-focus-border-color !default
//
//$ember-power-select-trigger-border: $ember-power-select-default-border !default
//$ember-power-select-active-trigger-border: $ember-power-select-default-focus-border !default
//$ember-power-select-dropdown-border: $ember-power-select-default-focus-border !default
//$ember-power-select-search-field-border: $ember-power-select-default-border !default
//$ember-power-select-search-field-focus-border: $ember-power-select-default-focus-border !default
//
//$ember-power-select-dropdown-top-border: $ember-power-select-dropdown-border !default
//$ember-power-select-dropdown-right-border: $ember-power-select-dropdown-border !default
//$ember-power-select-dropdown-bottom-border: $ember-power-select-dropdown-border !default
//$ember-power-select-dropdown-left-border: $ember-power-select-dropdown-border !default
//
//$ember-power-select-dropdown-contiguous-border: none !default
//
//$ember-power-select-multiple-option-border-color: gray !default
//$ember-power-select-multiple-option-border: 1px solid $ember-power-select-multiple-option-border-color !default
//
//// Borders radius
$ember-power-select-default-border-radius: 2px; //!default  // General border radius
//$ember-power-select-trigger-default-border-radius: $ember-power-select-default-border-radius //!default
//$ember-power-select-dropdown-default-border-radius: $ember-power-select-default-border-radius !default
//
$ember-power-select-opened-border-radius: 2px; //0 !default  // Border radius of the side of the dropdown and the trigger where they touch
//
//$ember-power-select-search-input-border-radius: 0 !default
//$ember-power-select-multiple-option-border-radius: $ember-power-select-default-border-radius !default
//
//// Other
$ember-power-select-focus-box-shadow: none; //!default
$ember-power-select-dropdown-margin: 0; // !default  // Margin between the dropdown and the trigger
$ember-power-select-dropdown-box-shadow: 0px 5px 2px -2px rgba(163, 203, 184, 0.8); // none !default
$ember-power-select-line-height: $line-height-base; //!default
//$ember-power-select-trigger-icon-color: $ember-power-select-border-color !default
//$ember-power-select-clear-btn-margin: 25px !default
//$ember-power-select-option-padding: 8px !default
//$ember-power-select-number-of-visible-options: 7 !default
//$ember-power-select-focus-outline: null !default
$ember-power-select-trigger-ltr-padding: 6px 12px; //!default
//$ember-power-select-trigger-rtl-padding: 0 8px 0 16px !default
//$ember-power-select-multiple-option-padding: 0 4px !default
