table {
  // Sorting
  a.sort {
    display: block;
    color: #555;
    cursor: pointer;

    &:hover {
      text-decoration: none; }

    i.fa {
      color: #bbb;
      float: right;
      margin-top: 2px; }

    &.sort-active i.fa {
      color: #555; } } }

.table-clickable tr {
  cursor: pointer; }

.btn-scroll {
  display: block;
  width: 50%;
  margin: 0 auto; }
