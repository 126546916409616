.callout {
  margin-bottom: $line-height-computed;
  padding: $callout-padding;
  border-radius: $callout-border-radius;
  font-weight: bold;

  h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 5px; }

  p:last-child {
    margin-bottom: 0; }

  .callout-link {
    color: $gray-dark;
    font-weight: bold; }

  ol {
    margin-bottom: 0; } }

.callout-danger {
  background-color: $callout-danger-bg;
  border-color: $callout-danger-border;
  color: $callout-danger-text;

  h4, h5, h6 {
    color: $callout-danger-text; } }

.callout-success {
  background-color: $callout-success-bg;
  border-color: $callout-success-border;
  color: $callout-success-text;

  h4, h5, h6 {
    color: $callout-success-text; } }

.callout-warning {
  background-color: $callout-warning-bg;
  border-color: $callout-warning-border;

  h4, h5, h6 {
    color: $callout-warning-text; } }

.callout-info {
  background-color: $callout-info-bg;
  border-color: $callout-info-border;

  h4, h5, h6 {
    color: $callout-info-text; } }
