.card {
  border-radius: $border-radius-base;
  background: #FFF;
  margin-top: 15px;
  box-shadow: 0px 3px 3px #e3e3e3;
  border: 1px #eee solid;

  .card-body,
  .card-head,
  .card-bottom {
    padding: 20px; }

  .card-title {
    margin-top: 0;
    padding-top: 0; } }
