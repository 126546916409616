.form-control {
  border: 1px #e3e3e3 solid;
  box-shadow: none;

  &:focus {
    outline: none;
    box-shadow: inherit;
    border: 1px desaturate(lighten($brand-primary, 20%), 20%) solid; } }

.form-inline {
  .form-control {
    width: 100%; } }

.breadcrumb {
  background: transparent;
  margin: 0;
  padding: 0; }

.pace {
  background: transparent; } // overriding white background

.pace-progress {
  height: 12px; // explicitly setting height due to the problem occurring in Firefox
  background-color: $brand-primary; }

.pace-activity {
  height: 12px; } // explicitly setting height due to the problem occurring in Firefox

.twitter-typeahead {
  width: 100%; }

.callout-in-app {
  position: fixed;
  z-index: 1000;
  width: auto;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  border-left-width: 0px;
  cursor: pointer;

  @media (min-width: $screen-sm-min) {
    left: $sidebar-width; } }

i.fa {
  &.user-active {
    color: $brand-primary; }

  &.user-deleted {
    color: $brand-danger; } }
