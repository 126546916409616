.control-label .required {
  font-size: 12px;
  font-weight: 200;
  color: $gray-light; }

.form-group {
  textarea {
    resize: vertical; }

  .input-group {
    .input-group-addon {
      border-color: #e3e3e3;
      .fa {
        font-size: 1.3em; } }

    .input-group-inner-fixed-width {
      min-width: 20px; } } }
