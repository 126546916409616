.login {
  margin-top: 50px;

  .card-login {
    @include fadeInUp();
    .card-head {
      padding: 10%; } }

  .logo {
    width: 90%;
    margin: 0 5%; } }

.card-login {
  max-width: 350px;
  margin: auto; }
